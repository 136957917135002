import { AxiosError } from 'axios';
import { del, edit, get, post } from '@/utils';
import { IAnnouncement, ICreateAnnouncement, IUpdateAnnouncement } from './types';

async function fetchAnnouncements(projId: string): Promise<IAnnouncement[]> {
	try {
		const res = await get(`api/protected/announcements?project=${projId}`);
		const data = res.data as unknown as { announcements: IAnnouncement[] };
		return data?.announcements || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm anunturile.');
	}
}

async function fetchAnnouncementsQuery(query: string): Promise<IAnnouncement[]> {
	try {
		const res = await get(`api/protected/announcements${query}`);
		const data = res.data as unknown as { announcements: IAnnouncement[] };
		return data?.announcements || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm anunturile.');
	}
}

async function fetchSingleAnnouncement(announcementId: string): Promise<IAnnouncement> {
	try {
		const res = await get(`api/protected/announcements/${announcementId}`);
		const data = res.data as unknown as { announcement: IAnnouncement };
		return data?.announcement || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm anunțul.');
	}
}

async function deleteAnnouncement(id: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/announcements/${id}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem anunțul');
	}
}

async function postAnnouncement(obj: ICreateAnnouncement): Promise<string | number> {
	try {
		const res = await post('api/protected/announcements', {...obj});
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa cream anunțul.');
	}
}

async function patchAnnouncement(id: string, updatedObj: IUpdateAnnouncement): Promise<string | number> {
	try {
		const res = await edit(`api/protected/announcements/${id}`, {...updatedObj});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm anunțul');
	}
}

export { 
	fetchAnnouncements, fetchSingleAnnouncement, postAnnouncement, 
	patchAnnouncement, deleteAnnouncement, fetchAnnouncementsQuery 
};

