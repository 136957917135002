import axios, { AxiosError, AxiosResponse } from 'axios';
import { del, edit, get, post } from '@/utils';
import { ICheckProjectName, IProject } from './types';
import { ICreateProjectSendData } from '@/providers/create-project/types';
import { CITIZEN_API_URL } from '@/utils/axios/constants';

const request = axios.create({
	withCredentials: true,
});

async function fetchProjects(): Promise<IProject[]> {
	try {
		const res = await get('api/protected/projects');
		const data = res.data as unknown as { projects: IProject[] };
		return data?.projects || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
};

async function fetchQueryProjects(query: string): Promise<IProject[]> {
	try {
		const res = await get(`api/protected/projects${query}`);
		const data = res.data as unknown as { projects: IProject[] };
		return data?.projects || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm datele.');
	}
};

async function fetchSingleProject(projId: string): Promise<IProject> {
	try {
		const res = await get(`api/protected/projects/${projId}`);
		const data = res.data as unknown as { project: IProject };
		return data?.project || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm proiectul.');
	}
};

async function postProject(
	projectObject: ICreateProjectSendData,
	addProjectEmit: (project: string) => void
): Promise<string | number> {
	try {
		const res = await post('api/protected/projects', {...projectObject});
		if(res?.status === 201 && projectObject?.status === 'published'){
			addProjectEmit(res?.data?._id);
		}
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să adăugăm proiectul.');
	}
}

async function postProjectName(params: ICheckProjectName): Promise<string | number> {
	try {
		const sendObject = params?.id ? params : { name: params.name };
		const res = await post('api/protected/projects/name/check', {data : {...sendObject}});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să verificăm proiectul.');
	}
}

async function postProjectGeometry(params: any): Promise<string | number> {
	try {
		const res = await post('api/protected/projects/geometry/check', {geometry: params});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să verificăm proiectul.');
	}
}

async function patchProject(
	projId: string, updatesObject: Omit<ICreateProjectSendData, 'elements'>
): Promise<string | number> {
	try {
		const res = await edit(`api/protected/projects/${projId}`, {...updatesObject});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să actualizăm proiectul.');
	}
}

async function deleteProject(projId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/projects/${projId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să ștergem proiectul.');
	}
};

async function fetchPosterQr(project: string): Promise<AxiosResponse<Blob>> {
	try {
		const res = await request.get(`${CITIZEN_API_URL}/api/protected/projects/${project}/poster`, {
			responseType: 'blob',
		});
		return res;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reușit să accesăm posterul.');
	}
}

export { 
	fetchProjects, postProject, fetchSingleProject, 
	deleteProject, patchProject,fetchPosterQr, fetchQueryProjects,
	postProjectName, postProjectGeometry
};
