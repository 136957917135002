import { useCallback, useEffect, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import { ICreateFSP, IFSP, IUpdateFSP, FSPsReturnType } from './types';
import { deleteFSP, fetchFSPs, fetchQueryFSPs, fetchSingleFSP, patchFSP, postFSP } from './actions';

interface ILoading {
    all: boolean;
    delete: boolean;
    create: boolean;
    update: boolean;
}

export function useFSPs<T extends IFSP | IFSP[]>(
	getFSPsOnRender: boolean | undefined = undefined,
	appFSPId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): FSPsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<ILoading>({ all: true, delete: false, create: false, update: false});
	const [enableQuery, setEnableQuery] = useState<boolean>(false);

	const toggleLoading = (type: 'all' | 'delete' | 'create' | 'update', value: boolean): void => {
		setLoading((prevState) => ({
			...prevState,
			[type]: value,
		}));
	};

	const getFSPs = useCallback(
		async () => {
			try {
				toggleLoading('all',true);
				const response = await fetchFSPs();
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryFSPs = useCallback(
		async (query: string) => {
			try {
				!enableQuery && setEnableQuery(true);
				toggleLoading('all',true);
				const response = await fetchQueryFSPs(query);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getFSP = useCallback(
		async (FSPId: string) => {
			try {
				toggleLoading('all',true);
				const response = await fetchSingleFSP(FSPId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('all',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeFSP = useCallback(
		async (FSPId: string, onSuccess?: () => void) => {
			try {
				toggleLoading('delete',true);
				const response = await deleteFSP(FSPId);
				if(response === 200) {
					setData(currentData => {
						if (Array.isArray(currentData)) {
							return currentData.filter(FSP => FSP._id !== FSPId) as T;
						}
						return currentData;
					});
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să ștergem administrația!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('delete',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createFSP = useCallback(
		async (FSPObj: ICreateFSP, onSuccess?: () => void) => {
			try {
				toggleLoading('create',true);
				const response = await postFSP(FSPObj);
				if(response === 201) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să adăugăm administrația!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('create',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editFSP = useCallback(
		async (FSPId: string, updatedObject: IUpdateFSP, onSuccess?: () => void) => {
			try {
				toggleLoading('update',true);
				const response = await patchFSP(FSPId, updatedObject);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să actualizăm administrația!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				toggleLoading('update',false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getFSPsOnRender && queryParam === undefined) {
			getFSPs();
		}
		// eslint-disable-next-line
	}, [getFSPsOnRender, queryParam]);

	useEffect(() => {
		if (appFSPId) {
			getFSP(appFSPId);
		}
		// eslint-disable-next-line
	}, [appFSPId]);

	useEffect(() => {
		if (queryParam && appFSPId === undefined) {
			getQueryFSPs(queryParam);
		} else if(appFSPId === undefined && enableQuery) {
			getFSPs();
		}
		// eslint-disable-next-line
	}, [queryParam, appFSPId]);

	return {
		data: data || ([] as IFSP[] as T),
		setData: setData as stateSetter<T>,
		loading: loading.all,
		loadingDelete: loading.delete,
		loadingCreate: loading.create,
		loadingUpdate: loading.update,
		createFSP,
		editFSP,
		getFSP,
		getFSPs,
		removeFSP
	};
}