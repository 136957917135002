import { stateSetter } from '@/utils';

export enum AuthState {
  Loading,
  SignedOut,
  SignedIn,
  NetworkError,
}

export type UserRole = 'superadmin' | 'admin' | 'moderator' | 'fsp' | undefined;

export interface IUser {
  _id: string;
  role: UserRole;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  isLoggedIn?: boolean;
  avatar?: string;
  metadata?: {
    coverPhoto?: string;
  }
}

/**
 * Type used to describe the data needed for logging in
 */
export interface IUserCredentials {
  email: string;
  password: string;
}

export interface IAuthContext {
  authState: AuthState;
  user: IUser | undefined;
  signIn: (data: IUserCredentials) => Promise<IUser | undefined>;
  signOut: () => Promise<void>;
  refreshAuthState: (authState: AuthState) => void;
  setUser: stateSetter<IUser | undefined>;
}
