import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import dayjs from 'dayjs';
import { 
	IAttachments, IChatContext, IChatRoom, IMessage, ISelectedChatUser, 
	ISubscribedProjects, IUserList 
} from './types';
import { ChatContext } from './context';
import { useSocketContext } from '../socket';
import { del, get, notify } from '@/utils';
import { AuthState, useAuthContext } from '../auth';
import { IRestrictObj, useFileUploader, useRestrictions } from '@/hooks';
import { initialSimpleFilter, useFiltersContext } from '../filters-provider/context';
import { ISimpleFilter } from '../filters-provider/types';
import { IChatQuery } from '../socket/types';

interface ILoadingUsers {
	all: boolean;
	details: boolean;
}

const sortRoomsByLastMessage = (rooms: IChatRoom[]): IChatRoom[] => rooms.sort((a, b) => {
	const aDate = a.lastMessage?.createdAt ? new Date(a.lastMessage.createdAt).getTime() : 0;
	const bDate = b.lastMessage?.createdAt ? new Date(b.lastMessage.createdAt).getTime() : 0;
	return bDate - aDate;
});

const formatSimpleFilter = (simpleFilter: ISimpleFilter): IChatQuery => {
	const formattedFilter: IChatQuery = {};

	if (simpleFilter.search) {
		formattedFilter.search = simpleFilter.search;
	}
	if (simpleFilter.status === 'published' || simpleFilter.status === 'archived') {
		formattedFilter.status = simpleFilter.status;
	}
	if (simpleFilter.county) {
		formattedFilter.county = simpleFilter.county;
	}
	if (simpleFilter.uat) {
		formattedFilter.uat = simpleFilter.uat;
	}
	if (simpleFilter.seen !== null) {
		formattedFilter.seen = simpleFilter.seen;
	}

	return formattedFilter;
};

export const ChatProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const { 
		socket, fetchRooms, joinRoom, leaveRoom, blockUserEmit, fetchQueryRooms,
		sendMessageToRoom, leaveAllRooms, deleteEmit, sendAttachmentsToRoom
	} = useSocketContext();
	const { simpleFilter, setApplyFilter, setClearFilter, setSimpleFilter } = useFiltersContext();

	const [chatRooms, setChatRooms] = useState<IChatRoom[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [selectedRoom, setSelectedRoom] = useState<IChatRoom | null>(null);
	const [roomMessages, setRoomMessages] = useState<IMessage[] | null>(null);
	const [loadingMessages, setLoadingMessages] = useState<boolean>(false);
	const [hasUserList, setHasUserList] = useState<boolean>(false);
	const [interestUserId, setInterestUserId] = useState<string | null>(null);
	const [selectedUser, setSelectedUser] = useState<ISelectedChatUser | null>(null);
	const [userTab, setUserTab] = useState<number>(1);
	const [loadingUsers, setLoadingUsers] = useState<ILoadingUsers>({ all: true, details: true});
	const [userList, setUserList] = useState<IUserList[] | null>(null);
	const [searchUserParam, setSearchUserParam] = useState<string | null>('');
	// projects
	const [selectedProject, setSelectedProject] = useState<ISubscribedProjects | null>(null);
	const [projects, setProjects] = useState<ISubscribedProjects[] | null>(null);
	const [loadingProjectMessages, setLoadingProjectMessages] = useState<boolean>(false);
	const [searchMessage, setSearchMessage] = useState<string | null>('');
	const [projectMessages, setProjectMessages] = useState<IMessage[] | null>(null);
	// media
	const { addFiles, removeFile, loading: loadingMedia} = useFileUploader<IAttachments[]>();
	const [media, setMedia] = useState<IAttachments[]| null>(null);
	const [openMedia, setOpenMedia] = useState<boolean>(false);

	const { blockChatUser, unblockUser, unmuteUser, loading: restrictLoading, muteChatUser } = useRestrictions();
	const { user, refreshAuthState } = useAuthContext();

	const getUserProjects = useCallback(async (userId: string) => {
		try {
			const response = await get(`api/protected/chat/rooms?user=${userId}`);
			if(response.status === 200) {
				const data = response.data as unknown as { projects: ISubscribedProjects[]};
				console.log(data?.projects);
				setProjects(data.projects);
			}
		} catch (e) {
			console.log('user error', e);
		}
	}, []);

	useEffect(() => {
	  if(selectedUser && selectedUser?._id){
			getUserProjects(selectedUser?._id);
	  } else {
			setProjects(null);
	  }
	// eslint-disable-next-line
	}, [selectedUser?._id])
	
	useEffect(() => {
		// eslint-disable-next-line
		setApplyFilter(() => () => {
			setLoading(true);
			fetchQueryRooms(formatSimpleFilter(simpleFilter));
		});
		// eslint-disable-next-line
		setClearFilter(() => () => {
			setSimpleFilter(initialSimpleFilter);
			setLoading(true);
			socket?.emit('fetch-rooms');
		});
		// eslint-disable-next-line
	}, [simpleFilter]);

	const handleUnblockSuccess = (): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: selectedUser?.muted ?  'muted' : 'active',
				blockMuteHistory: [
					{
						event: 'unblock',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory: [])
				],
				blocked: false,
			});
			notify.info('Utilizator deblocat');
		}
	};

	const handleUnblock = (userId: string, onSuccess: () => void): void => {
		unblockUser(userId, onSuccess);
	};

	const handleBlockSuccess = ( restrictObj: IRestrictObj): void => {
		if (selectedUser != null) {
			setSelectedUser({
				...selectedUser,
				status: 'blocked',
				blockMuteHistory: [
					{
						event: 'block',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
							reason: restrictObj.reason,
							until: restrictObj.until
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory: [])
				],
				blocked: true,
			});
			notify.success('Utilizator blocat');
		}
	};

	const handleBlock = (userId: string, restrictObj: IRestrictObj, onSuccess: () => void): void => {
		blockChatUser(
			userId, 
			{ reason: restrictObj.reason, until: restrictObj.until}, 
			(messages) => {
				blockUserEmit(messages);
				onSuccess && onSuccess();
			}
		);
	};
	
	// Mute actions
	const handleUnmuteSuccess = (): void => {
		if (selectedUser != null) {

			setSelectedUser({
				...selectedUser,
				status: selectedUser?.blocked ?  'blocked' : 'active',
				blockMuteHistory: [
					{
						event: 'unmute',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory: [])
				],
				muted: false,
			});
			notify.info('Utilizator derestricționat');
		}
	};

	const handleUnmute = (userId: string, onSuccess: () => void): void => {
		unmuteUser(userId, onSuccess);
	};

	const handleMuteSuccess = ( restrictObj: IRestrictObj): void => {
		if (selectedUser != null) {

			setSelectedUser({
				...selectedUser,
				status: selectedUser?.status === 'blocked' ?  selectedUser?.status : 'muted',
				blockMuteHistory: [
					{
						event: 'mute',
						details: {
							at: dayjs().valueOf(),
							author: `${user?.firstName} ${user?.lastName}`,
							role: user?.role,
							reason: restrictObj.reason,
							until: restrictObj.until
						}
					},
					...(selectedUser?.blockMuteHistory ? selectedUser.blockMuteHistory: [])
				],
				muted: true,
			});
			notify.success('Utilizator restricționat');
		}
	};

	const handleMute = (userId: string, restrictObj: IRestrictObj, onSuccess: () => void): void => {
		muteChatUser(
			userId, 
			{ reason: restrictObj.reason, until: restrictObj.until}, 
			(messages) => {
				blockUserEmit(messages);
				onSuccess && onSuccess();
			}
		);
	};

	const toggleUserLoading = (key: 'all' | 'details', val: boolean): void => {
		setLoadingUsers((prev) => ({
			...prev,
			[key]: val
		}));
	};

	// eslint-disable-next-line
	useEffect(() => {
		if (socket) {
			socket.on('rooms', (rooms: IChatRoom[]) => {
				const sortedRooms = sortRoomsByLastMessage(rooms);
				setChatRooms(sortedRooms);
				setLoading(false);
			});
	
			socket.on('room', (room: IChatRoom) => {
				setChatRooms((prev) => {
					const updatedRooms = prev.map((r) => (r.id === room.id ? room : r));
					return sortRoomsByLastMessage(updatedRooms);
				});
				setLoading(false);
			});

			socket.on('kick', () => {
				notify.info('Proiectul a fost arhivat!');
			});

			socket.on('duplicate-auth', (data: {message: string}) => {
				notify.info(data?.message);
			});

			socket.on('trigger-fetch-rooms', () => {
				socket.emit('fetch-rooms');
			});

			socket.on('trigger-fetch-room', (data: {room: string}) => {
				socket.emit('fetch-room', data);
			});

			socket.on('room-messages', (data: { messages: IMessage[], room: string }) => {
				if (selectedRoom && data.room === selectedRoom.id) {
					const incomingMessages = Array.isArray(data.messages) ? data.messages : [];
					if(roomMessages){
						setRoomMessages([...roomMessages, ...incomingMessages]);
					} else {
						// eslint-disable-next-line
						setRoomMessages([...incomingMessages?.reverse()]);
					}
					setLoadingMessages(false);
				}
			});

			socket.on('remove-message', (messageObj: { message: string }) => {
				const messageId = messageObj.message;
				if (roomMessages) {
					// Find the index of the message to be updated
					const index = roomMessages.findIndex((prevMessage) => {
						const prevMessageId = String(prevMessage._id);
						return prevMessageId === messageId;
					});
			
					if (index !== -1) {
						const updatedMessages = [...roomMessages];
						updatedMessages[index] = { ...updatedMessages[index], deleted: true };
						
						// Set the updated array as the new state
						setRoomMessages(updatedMessages);
					} else {
						console.log('Message not found:', messageId);
					}
				}
			});						

			socket.on('errors', (error: {statusCode: number, message: string}) => {
				console.error('Socket error:', error);
				notify.error(error?.message);
				setLoading(false);
				if(error?.statusCode === 401 && error?.message === 'Unauthorized'){
		  			refreshAuthState(AuthState.SignedOut);
				}
			});

			// Clean up listeners when the component unmounts or socket changes
			// eslint-disable-next-line
			return () => {
				socket.off('rooms');
				socket.off('kick');
				socket.off('room');
				socket.off('room-messages');
				socket.off('errors');
				socket.off('trigger-fetch-room');
				socket.off('trigger-fetch-rooms');
				socket.off('remove-message');
				socket.off('duplicate-auth');
			};
		}
		// eslint-disable-next-line
	}, [socket,selectedRoom, roomMessages]);

	const fetchChatRooms = useCallback(() => {
		try {
			setLoading(true);
			// Use the socket to fetch rooms
			fetchRooms();
		} catch (e) {
			console.error('Failed to fetch chat rooms:', e);
			setLoading(false);
		}
	}, [fetchRooms]);

	const sendRoomMessage = useCallback((room: string, message: string) => {
		sendMessageToRoom(room, message);
	}, [sendMessageToRoom]);

	const setSearch = useCallback((newString: string) => {
		const updateSearchParam = debounce((searchValue: string) => {
		  if (searchValue.length > 2) {
				setSearchUserParam(searchValue);
		  } else {
				setSearchUserParam(null);
		  }
		}, 300);
	
		updateSearchParam(newString);
		// eslint-disable-next-line
		return () => {
		  updateSearchParam.cancel();
		};
	  }, []);

	  const setSearchProjectMessage = useCallback((newString: string) => {
		const updateSearchParam = debounce((searchValue: string) => {
		  if (searchValue.length > 2) {
				setSearchMessage(searchValue);
		  } else {
				setSearchMessage(null);
		  }
		}, 300);
	
		updateSearchParam(newString);
		// eslint-disable-next-line
		return () => {
		  updateSearchParam.cancel();
		};
	  }, []);

	const selectRoom = useCallback((room: IChatRoom) => {
		setHasUserList(false);
		setRoomMessages(null);
		setLoadingMessages(true);
		setSearchUserParam(null);
		setSearchMessage(null);
		setSelectedUser(null);
		setInterestUserId(null);
		setSelectedProject(null);
		setProjectMessages(null);
		setMedia(null);
		joinRoom(room.id);
		setSelectedRoom(room);
	}, [joinRoom]);

	const deselectRoom = useCallback((room: string) => {
		leaveRoom(room);
		setSearchUserParam(null);
		setSearchMessage(null);
		setSelectedRoom(null);
		setSelectedUser(null);
		setInterestUserId(null);
		setRoomMessages([]);
		setSelectedProject(null);
		setProjectMessages(null);
	}, [leaveRoom]);

	const clearRooms = useCallback(() => {
		leaveAllRooms();
		setSearchUserParam(null);
		setSearchMessage(null);
		setSelectedRoom(null);
		setSelectedUser(null);
		setInterestUserId(null);
		setRoomMessages([]);
		setSelectedProject(null);
		setProjectMessages(null);
	}, [leaveAllRooms]);

	const getUserList = useCallback(async (gueryParam: string) => {
		try {
			toggleUserLoading('all', true);
			const response = await get(`api/protected/chat/users?${gueryParam}`);
			if(response.status === 200) {
				const data = response.data as unknown as { users: ISelectedChatUser[]};
				setUserList(data.users);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			toggleUserLoading('all', false);
		}
	}, []);

	const getUserDetails = useCallback(async (userId: string) => {
		try {
			toggleUserLoading('details', true);
			const response = await get(`api/protected/chat/users/${userId}`);
			if(response.status === 200) {
				const data = response.data as unknown as { user: ISelectedChatUser};
				setSelectedUser(data.user);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			toggleUserLoading('details', false);
		}
	}, []);

	const getProjectMessages = useCallback(async (gueryParam: string) => {
		try {
			setLoadingProjectMessages(true);
			const response = await get(`api/protected/chat/messages?${gueryParam}`);
			if(response.status === 200) {
				const data = response.data as unknown as { messages: IMessage[]};
				setProjectMessages(data.messages);
			}
		} catch (e) {
			console.log('user error', e);
		} finally {
			setLoadingProjectMessages(false);
		}
	}, []);

	const deleteProjectMessage = useCallback(async (messageId: string, onSuccess?: () => void) => {
		try {
			setLoadingProjectMessages(true);
			const response = await del(`api/protected/chat/messages/${messageId}`);
			if(response.status === 200) {
				setProjectMessages(() => projectMessages?.map((message) => 
					message?._id === messageId ? {...message, deleted: true} : message) || []);
				onSuccess && onSuccess();
				deleteEmit(messageId);
			}
		} catch (e) { 
			console.log('user error', e);
		} finally {
			setLoadingProjectMessages(false);
		}
		// eslint-disable-next-line
	}, [projectMessages]);

	const addMediaMessage = (): void => {
		selectedRoom && media && sendAttachmentsToRoom(selectedRoom.id, media);
		setMedia(null);
		setOpenMedia(false);
	};

	const removeMedia = (): void => {
		if (!media || media.length === 0) return;
	
		const removeNext = (index: number): void => {
			if (index >= media.length) {
				setMedia(null);
				setOpenMedia(false);
				return;
			}
			removeFile(media[index].url, () => removeNext(index + 1));
		};
	
		removeNext(0);
	};

	useEffect(() => {
		if(selectedRoom && hasUserList){
			const query = searchUserParam && searchUserParam?.length > 2 ? 
				`room=${selectedRoom.id}&search=${searchUserParam}` : `room=${selectedRoom.id}`;
			getUserList(query);
		}
		// eslint-disable-next-line
	}, [selectedRoom, hasUserList, searchUserParam]);

	useEffect(() => {
		if(interestUserId){
			getUserDetails(interestUserId);
		} else {
			setSelectedUser(null);
		}
		// eslint-disable-next-line
	}, [interestUserId]);

	// fetch project messages
	useEffect(() => {
		if(selectedProject && selectedUser){
			const query = searchMessage && searchMessage?.length > 2 ? 
				`room=${selectedProject?._id}&user=${selectedUser?._id}&search=${searchMessage}` : 
				`room=${selectedProject?._id}&user=${selectedUser?._id}`;
			getProjectMessages(query);
		}
		// eslint-disable-next-line
	}, [selectedProject, selectedUser, searchMessage]);

	const value: IChatContext = useMemo(() => ({
		chatRooms,
		fetchChatRooms,
		loading,
		selectedRoom,
		selectRoom,
		setSelectedRoom,
		loadingMessages,
		roomMessages,
		setRoomMessages,
		deselectRoom,
		sendRoomMessage,
		hasUserList,
		setHasUserList,
		selectedUser,
		setSelectedUser,
		userTab,
		setUserTab,
		getUserList,
		loadingUsers: loadingUsers.all,
		loadingUserDetails: loadingUsers.details,
		userList,
		setSearch,
		setSearchUserParam,
		interestUserId,
		setInterestUserId,
		restrictLoading,
		handleBlock,
		handleBlockSuccess,
		handleMute,
		handleMuteSuccess,
		handleUnblock,
		handleUnblockSuccess,
		handleUnmute,
		handleUnmuteSuccess,
		selectedProject,
		setSelectedProject,
		loadingProjectMessages,
		projectMessages,
		clearRooms,
		loadingMedia,
		addFiles,
		addMediaMessage,
		removeMedia,
		media,
		setMedia,
		openMedia,
		setOpenMedia,
		setSearchMessage,
		setSearchProjectMessage,
		deleteProjectMessage,
		projects
		// eslint-disable-next-line
	}), [
		chatRooms, fetchChatRooms, loading, selectedRoom, selectRoom,
		loadingMessages, roomMessages, hasUserList, selectedUser, userTab,
		loadingUsers, userList, interestUserId, restrictLoading, selectedProject,
		loadingProjectMessages, projectMessages, loadingMedia, media, openMedia, projects
	]);

	return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
