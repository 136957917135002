import { useCallback, useEffect, useState } from 'react';
import { deleteUser, fetchAppUsers, fetchQueryUsers, fetchSingleAppUser } from './actions';
import { AppUsersReturnType, IAppUser } from './types';
import { notify, stateSetter } from '@/utils';

export function useAppUsers<T extends IAppUser | IAppUser[]>(
	getAppUsersOnRender: boolean | undefined = undefined,
	appUserId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): AppUsersReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);

	const getAppUsers = useCallback(
		async () => {
			try {
				setLoading(true);
				const response = await fetchAppUsers();
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryUsers = useCallback(
		async (query: string) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchQueryUsers(query);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAppUser = useCallback(
		async (userId: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleAppUser(userId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeUser = useCallback(
		async (userId: string, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await deleteUser(userId);
				if (response === 200 ){
					notify.success('Utilizatorul a fost șters!');
					onSuccess && onSuccess();
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (getAppUsersOnRender) {
			getAppUsers();
		}
		// eslint-disable-next-line
	}, [getAppUsersOnRender]);

	useEffect(() => {
		if (appUserId) {
			getAppUser(appUserId);
		}
		// eslint-disable-next-line
	}, [appUserId]);

	useEffect(() => {
		if (queryParam && appUserId === undefined) {
			getQueryUsers(queryParam);
		} else if(appUserId === undefined && enableQuery) {
			getAppUsers();
		}
		// eslint-disable-next-line
	}, [queryParam, appUserId]);

	return {
		data: data || ([] as IAppUser[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getAppUser,
		getAppUsers,
		removeUser,
		loadingCTA
	};
}