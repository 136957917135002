import { useCallback, useEffect, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import { AnnouncementsReturnType, IAnnouncement, ICreateAnnouncement, IUpdateAnnouncement } from './types';
import { 
	deleteAnnouncement, fetchAnnouncements, fetchAnnouncementsQuery, fetchSingleAnnouncement, 
	patchAnnouncement, postAnnouncement 
} from './actions';

export function useAnnouncements<T extends IAnnouncement | IAnnouncement[]>(
	projectId: string | undefined = undefined,
	parsId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): AnnouncementsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);

	const getAnnouncements = useCallback(
		async (proj: string) => {
			try {
				setLoading(true);
				const response = await fetchAnnouncements(proj);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAnnouncementsQuery = useCallback(
		async (query: string) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchAnnouncementsQuery(query);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAnnouncement = useCallback(
		async (announcementId: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleAnnouncement(announcementId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeAnnouncement = useCallback(
		async (id: string, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await deleteAnnouncement(id);
				if(response === 200) {
					setData(currentData => {
						if (Array.isArray(currentData)) {
							return currentData.filter(ann => ann._id !== id) as T;
						}
						return currentData;
					});
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am resit sa stergem anunțul');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createAnnouncement = useCallback(
		async (obj: ICreateAnnouncement, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await postAnnouncement(obj);
				if(response === 201) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa cream anunțul');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editAnnouncement = useCallback(
		async (id: string, updatedObject: IUpdateAnnouncement, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await patchAnnouncement(id, updatedObject);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reușit să actualizăm anunțul');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (projectId) {
			getAnnouncements(projectId);
		}
		// eslint-disable-next-line
	}, [projectId]);

	useEffect(() => {
		if (parsId) {
			getAnnouncement(parsId);
		}
		// eslint-disable-next-line
	}, [parsId]);

	useEffect(() => {
		if (queryParam && parsId === undefined && projectId) {
			getAnnouncementsQuery(queryParam);
		} else if(parsId === undefined && enableQuery && projectId) {
			getAnnouncements(projectId);
		}
		// eslint-disable-next-line
	}, [queryParam, parsId]);

	return {
		data: data || ([] as IAnnouncement[] as T),
		setData: setData as stateSetter<T>,
		loading,
		loadingCTA,
		getAnnouncement,
		getAnnouncements,
		createAnnouncement,
		editAnnouncement,
		removeAnnouncement,
		getAnnouncementsQuery
	};
}